export const CLOUDFRONT_BASE_URL = "https://d2vdeuvkyk5mrr.cloudfront.net/";

export const CHAT_DEMO =
  CLOUDFRONT_BASE_URL + "landing-page-videos/chat-demo.mp4";
export const CHAT_DEMO_2 =
  CLOUDFRONT_BASE_URL + "landing-page-videos/chat-demo-2.mp4";

export const NOTEBOOK_DEMO_1_FAST =
  CLOUDFRONT_BASE_URL + "landing-page-videos/notebook-demo-1-fast.mp4";

export const FEATURE_SHOWCASE_AI =
  CLOUDFRONT_BASE_URL + "landing-page/feature-showcase-ai.svg";

export const FEATURE_SHOWCASE_DATA_SOURCES =
  CLOUDFRONT_BASE_URL + "landing-page/feature-showcase-data-sources.svg";

export const FEATURE_SHOWCASE_SELF_HOSTED =
  CLOUDFRONT_BASE_URL + "landing-page/feature-showcase-self-hosted.svg";

export const FEATURE_SHOWCASE_STRONG_SQL =
  CLOUDFRONT_BASE_URL + "landing-page/feature-showcase-strong-sql.svg";
