import React, { useRef, useState } from "react";
import { AnimatePresence, motion, useMotionValue } from "framer-motion";
import cx from "classnames";
const Video = ({
  srcs = [],
  showPagination = true,
  paginationButtons = [],
}) => {
  const [active, setActive] = useState(0);
  const timerId = useRef();
  const reset = () => {
    cancelAnimationFrame(timerId.current);
    x.set(0);
    duration.set(0);
    animationStartTime.set(0);
    videoStartTime.set(0);
  };
  const handleActive = () => {
    setActive(active > srcs.length - 2 ? 0 : active + 1);
    reset();
  };
  const handleChange = (index) => {
    if (index === active) return;
    reset();
    setActive(index);
  };

  const x = useMotionValue(0);
  const duration = useMotionValue(0);
  const animationStartTime = useMotionValue(0);
  const videoStartTime = useMotionValue(0);

  const handleUpdateTime = (e) => {
    const current = e.currentTarget.currentTime;
    const total = e.currentTarget.duration;

    duration.set(total);
    animationStartTime.set(Date.now());
    videoStartTime.set(current);

    updateTime();
  };
  const updateTime = () => {
    const elapsedTime = (Date.now() - animationStartTime.get()) / 1000;
    const currentTime = elapsedTime + videoStartTime.get();

    if (currentTime <= duration.get()) {
      x.set((currentTime / duration.get()) * 80);
      timerId.current = requestAnimationFrame(updateTime);
    }
  };

  return (
    <div className="relative">
      <div className=" h-full max-sm:h-[210px] w-full">
        <AnimatePresence mode="wait">
          {srcs.map((src, index) =>
            active === index ? (
              <motion.video
                exit={{
                  opacity: 0,
                  transition: { type: "tween", duration: 0.2 },
                }}
                key={index}
                src={src}
                className=" w-full h-full rounded-[5px]"
                onPlay={handleUpdateTime}
                onEnded={handleActive}
                autoPlay
                muted
                playsInline
                controls={false}
              />
            ) : null
          )}
        </AnimatePresence>
      </div>
      {showPagination && (
        <div className=" absolute left-1/2 -translate-x-1/2 bottom-[-50px] z-50">
          <div className=" inline-flex items-center justify-center  mt-[20px] bg-white rounded-[2px]">
            {paginationButtons.map((btn, index) => (
              <div
                key={index}
                className={cx(
                  " flex flex-col justify-between px-2 py-1 rounded-[2px]",
                  {
                    " bg-slate-100": index === active,
                  }
                )}
                onClick={() => handleChange(index)}
              >
                <button>{btn}</button>
                <div
                  className={cx(
                    " h-[3px] rounded-[2px] w-[70px] bg-[#a9a9a9] relative overflow-hidden cursor-pointer"
                  )}
                >
                  <motion.div
                    className=" absolute -left-full w-full h-full  z-10"
                    style={{
                      x: active === index ? x : 0,
                      height: "100%",
                      backgroundColor:
                        active === index ? "royalblue" : "transparent",
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Video;
