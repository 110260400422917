import React from "react";
import { FaDiscord } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { routesURL } from "../urls";
const LinkItems = ({ title = "", links = [] }) => {
  return (
    <div className="flex flex-col gap-y-7 h-full shrink-0 max-sm:basis-1/2">
      <h6 className=" text-[#fdfdfd] text-[15px] select-none">{title}</h6>
      {links.map((link, index) => (
        <a
          href={link.link}
          key={index}
          target="__blank"
          className=" text-[#a9a9a9] text-[13px]"
        >
          {link.name}
        </a>
      ))}
    </div>
  );
};
const Footer = () => {
  const footerLinks = [
    {
      title: "Product",
      links: [
        { link: routesURL.pricing, name: "Pricing" },
        { link: "/#features", name: "Features" },
        { link: "/#FAQ", name: "FAQ" },
        // { link: "#", name: "Road map" },
      ],
    },
    {
      title: "Company",
      links: [
        { link: routesURL.blog, name: "Blog" },
        { link: routesURL.about, name: "About" },
        { link: routesURL.privacyPolicy, name: "Privacy policy" },
      ],
    },
    {
      title: "Guides",
      links: [
        { link: "/guides/start-guide", name: "Start guide" },
        { link: "/guides/datasource-guide", name: "Data source guide" },
      ],
    },
    {
      title: "Community",
      links: [
        {
          link: "https://www.linkedin.com/company/flamingos/",
          name: "Linked in",
        },
        { link: "https://discord.gg/xWusHsPx", name: "Discord" },
      ],
    },
  ];
  return (
    <footer className=" border-t border-t-[rgba(30,30,30,1)] bg-black-6 px-60 max-sm:px-6 pt-[60px] py-20 h-[300px] max-sm:h-auto">
      <div className=" flex max-sm:flex-col items-center max-sm:items-stretch  justify-between h-full ">
        <div className=" flex flex-col max-sm:flex-row justify-between h-full">
          <h2 className=" font-geoGraph text-[23px] font-medium text-[#fdfdfd]">
            flamingos
          </h2>
          <div className=" flex items-center  gap-x-6">
            <a
              href="https://discord.gg/xWusHsPx"
              target="__blank"
              aria-label="Discord for flamingos"
            >
              <FaDiscord size={25} className=" text-[#a9a9a9]" />
            </a>
            <a
              href="https://www.linkedin.com/company/flamingos/"
              target="__blank"
              aria-label="Linked in for flamingos"
            >
              <FaLinkedin size={25} className=" text-[#a9a9a9]" />
            </a>
          </div>
        </div>
        <div className=" flex items-center justify-between max-sm:flex-wrap h-full  basis-[45%] shrink-0 text-[#ededed] font-inter text-[15px] max-sm:gap-y-[30px] max-sm:mt-10">
          {footerLinks.map((item, index) => (
            <LinkItems title={item.title} links={item.links} key={index} />
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
