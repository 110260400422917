import Link from "next/link";
import React, { useState } from "react";
import cx from "classnames";
import { routesURL } from "../urls";
import Video from "./Video";
import { CHAT_DEMO_2, NOTEBOOK_DEMO_1_FAST } from "@/static-urls";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoCloseOutline } from "react-icons/io5";
const HamBurger = ({ links = [] }) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);

  return (
    <div className=" max-sm:block hidden">
      <RxHamburgerMenu size={20} className=" text-white" onClick={handleShow} />
      <div
        className={cx(
          " fixed flex items-center justify-center top-0 left-0 bg-black-6 z-[999] h-screen w-screen text-[#ededed] transition-transform duration-200",
          {
            " translate-x-full": !show,
            "translate-x-0": show,
          }
        )}
      >
        <ul className=" text-[2rem]">
          {links.map((link, index) => (
            <li className=" mt-3" key={index}>
              <Link
                aria-label="flamingos-mobile-view-link"
                href={link.link}
                onClick={handleHide}
              >
                {link.text}
              </Link>
            </li>
          ))}
        </ul>
        <button className=" absolute top-6 right-6" onClick={handleHide}>
          <IoCloseOutline size={28} />
        </button>
      </div>
    </div>
  );
};
const NavLink = ({ link, text }) => (
  <li>
    <Link
      href={link}
      className=" hover:text-[#FDFDFD] h-full w-full font-inter"
      aria-label={`flamingos-navigation-link-${text}`}
    >
      {text}
    </Link>
  </li>
);
const Nav = () => {
  const links = [
    {
      text: "About",
      link: routesURL.about,
    },
    {
      text: "Features",
      link: "/#features",
    },
    {
      text: "Pricing",
      link: routesURL.pricing,
    },
    {
      text: "Blog",
      link: routesURL.blog,
    },
    {
      text: "FAQ",
      link: "/#FAQ",
    },
  ];
  return (
    <>
      <nav className=" mx-auto lg:w-[850px] max-md:w-[90%] max-md:overflow-x-scroll no-scrollbar  flex items-center justify-between px-[20px] py-[8px] bg-[#0F0F0F] border-[#292929] border rounded-[15px] ">
        <Link href={routesURL.index}>
          <div className=" text-[24px] text-[#fdfdfd] font-medium max-sm:text-[18px]">
            flamingos
          </div>
        </Link>
        <ul className=" flex items-center justify-between text-[#A9A9A9] gap-x-[40px] text-[13px] max-sm:hidden">
          {links.map((item, index) => (
            <NavLink key={index} text={item.text} link={item.link} />
          ))}
        </ul>

        <div className=" flex items-center justify-center gap-x-4">
          <Link href={routesURL.login} target="__blank">
            <button className=" bg-[#f9f9f9] w-[68px] h-[32px] max-sm:w-[58px] max-sm:h-[28px] flex items-center justify-center font-inter rounded-[10px] font-semibold text-[12px] max-sm:text-[11px] hover:scale-105 duration-200">
              Log in
            </button>
          </Link>
          <Link href={routesURL.signup} target="__blank">
            <button className="bg-[rgba(123,123,123,.2)] w-[78px] h-[32px] max-sm:w-[58px] max-sm:h-[28px] flex items-center justify-center font-inter rounded-[10px] font-semibold text-[12px]  max-sm:text-[11px] text-[#FFFFFF] hover:scale-105 duration-200">
              Sign up
            </button>
          </Link>
          <HamBurger links={links} />
        </div>
      </nav>
    </>
  );
};
const Pattern = () => (
  <div className=" max-sm:hidden absolute w-full h-[100%] left-1/3 top-[50px] square-pattern bg-black-6" />
);
const Hero = () => {
  return (
    <div className=" h-[calc(100vh-50px-30px)] max-sm:h-auto relative overflow-x-hidden overflow-y-hidden max-sm:overflow-visible">
      <Pattern />
      <div className=" flex max-sm:flex-col items-center max-sm:items-start justify-center h-full relative z-50 px-32 max-sm:px-6 max-sm:pt-10 gap-x-[60px]">
        <div className=" basis-[40%] relative max-sm:basis-auto">
          <h1 className="text-[40px] max-sm:text-[32px] font-medium text-[#EDEDED] max-sm:text-center">
            Serverless Data Analytics Platform
            <br />
            {/* Data Analytics */}
          </h1>
          <p className=" text-[#A9A9A9] mt-7 font-inter text-[14px] max-sm:text-[16px] max-sm:text-center">
            Flamingos is a high performance serverless data platform that enables you to manage and analyse
            your data in an easy to use, blazing fast and AI powered way.
          </p>
          <p className=" text-[#EDEDED] mt-7 font-inter text-[13px] max-sm:text-[15px] font-semibold max-sm:text-center">
            No infra setup, No complex data pipelines, No expensive cloud bills
          </p>
          <Link href={routesURL.login} target="__blank" className=" ">
            <button className=" bg-white px-[20px] py-[8px] rounded-[10px] font-inter font-semibold text-[13px] antialiased mt-[40px] block hover:scale-105 transition-transform duration-200 max-sm:mx-auto">
              30-Day FREE Trial
            </button>
          </Link>
        </div>
        <div className=" basis-[70%] max-sm:basis-full max-sm:self-center max-sm:mt-[80px]">
          <Video
            srcs={[CHAT_DEMO_2,NOTEBOOK_DEMO_1_FAST]}
            paginationButtons={["Chat", "Notebook"]}
          />
        </div>
      </div>
    </div>
  );
};
const Header = ({ children, showHero = true }) => {
  return (
    <React.Fragment>
      <header
        className={cx(" bg-black-6 pt-[30px] font-geoGraph", {
          "h-screen max-sm:h-auto": showHero,
        })}
      >
        <Nav />
        {showHero && <Hero />}
      </header>
      {children}
    </React.Fragment>
  );
};

export default Header;
